<!-- =========================================================================================
  Author: Hazem Ashraf
  Author URL: https://www.linkedin.com/in/hazem-ashraf-1242169b/
========================================================================================== -->

<template>
  <div id="data-list">
    <vx-card
      ref="filterCard"
      title="Adding new area"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
     <validation-observer ref="observer" v-slot="{ invalid }">
      <form>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75"
              ><span class="text-primary">*</span>Area NAME</label
            >
             <validation-provider
                    v-slot="{ errors }"
                    name="area_name"
                    rules="required|min:2"
                  >
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-terminal"
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              icon-no-border
              v-model="area_name"
            />
             </validation-provider>
          </div>
          <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75">
              <span class="text-primary">*</span>CITY
            </label>
            <validation-provider
                    v-slot="{ errors }"
                    name="city"
                    rules="min:1"
                  >
                 
            <v-select
              label="name"
              :class="{'err':!!errors[0]}"
              :reduce="name => name.id"
              :options="cities"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="city"
              class="mb-4"
              placeholder="City"
            />
            </validation-provider>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75"
              ><span class="text-primary">*</span>LATITUDE</label
            >
            <validation-provider
                    v-slot="{ errors }"
                    name="lat"
                    rules="required|min:2"
                  >
            <vs-input
             :danger="!!errors[0]"
              :danger-text="errors[0]"
              class="w-full"
              icon-pack="feather"
              icon="icon-terminal"
              icon-no-border
              v-model="lat"
            />
            </validation-provider>
          </div>
          <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75"
              ><span class="text-primary">*</span>LONGITUDE</label
            >
            <validation-provider
                    v-slot="{ errors }"
                    name="lng"
                    rules="required|min:2"
                  >
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-terminal"
              icon-no-border
              v-model="lng"
              :danger="!!errors[0]"
              :danger-text="errors[0]"
            />
            </validation-provider>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75"
              ><span class="text-primary">*</span>RADIUS</label
            >
            <validation-provider
                    v-slot="{ errors }"
                    name="radius"
                    rules="required|min:2"
                  >
            <vs-input
            :danger="!!errors[0]"
              :danger-text="errors[0]"
              class="w-full"
              icon-pack="feather"
              icon="icon-terminal"
              icon-no-border
              v-model="radius"
            />
            </validation-provider>
          </div>
        </div>

        <vs-button
          :disabled="invalid"
          type="filled"
          @click="addArea()"
          class="mb-2"
          >SUBMIT</vs-button>
      </form>
     </validation-observer>
    </vx-card>
  </div>
</template>

<script>
import axios from "@/axios.js";
import vSelect from "vue-select";
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});
// import moduleCitiesManagement from "@/store/get-all-cities/moduleCitiesManagement.js";
export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      cities:[],
      city: null,
      city_id: null,
      lat: null,
      lng: null,
      radius: null,
      area_name: null,
    };
  },
  methods: {
    addArea() {
      this.$vs.loading();

      axios
        .post(`${process.env.VUE_APP_BASE_URI}/api/admin/areas` , {
          name : this.area_name ,
           city_id : this.city,
            latitude : this.lat ,
             longitude : this.lng ,
              radius : this.radius
        })
        .then(response => {
           this.$vs.loading.close();
          if(response.status  === 200 ) {
            this.clearData();
               this.$vs.notify({
                  title: "Success",
                  text: "Area is added",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "success",
              });
              this.$router.push("../GetAll/Areas");
          }
          else{
            this.$vs.notify({
                  title: "Error",
                  text: "Area is not added",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",

              });
          }
        })
         .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        });
    },

    resetColFilters(card) {
      card.removeRefreshAnimation(100);
      this.city = this.area_name = this.lng = this.lat = this.radius = null;
    },
    clearData() {
      this.city_name = this.area_name = this.lng = this.lat = this.radius = null;
    }
  },
  created() {
    //   if (!moduleCitiesManagement.isRegistered) {
    //   this.$store.registerModule(
    //     "city",
    //     moduleCitiesManagement
    //   );
    //   moduleCitiesManagement.isRegistered = true;
    // }
 axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/cities`)
        .then(response => {
          this.cities = response.data.response.data;
        })
        .catch(error => {
          this.$vs.notify({
          title: "Error",
          text: error,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
        });
}
}
</script>
